<template>
    <workspace-wrapper>
        <template #workspace>
            <keno-workspace :state-data="stateData"/>
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import KenoWorkspace from "./KenoWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        resultTickets: [{
                            numbers: null,
                            bet: null,
                            matchCount: null,
                            totalCount: null,
                            matchNumbers: null,
                            coefficient: null,
                            win: null,
                        }],
                        resultTicket: null,
                    },
                    denom: null
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        KenoWorkspace
    }
}
</script>

<style scoped>

</style>
